<template>
  <n-form
    ref="formRef"
    :label-width="100"
    :model="model"
    :rules="rules"
    label-placement="left"
  >
    <summary-form-items v-model="model.resolution" />
    <n-form-item label=" ">
      <span class="mr-2">
        {{
          t("issues.detail.tabs.actions.cancelRent.form.confirmationReverseTransactions")
        }}
      </span>
      <n-radio-group
        v-model:value="model.flags.showReverseTransactions"
        name="rgReverseTx"
      >
        <n-space>
          <n-radio :value="1" :label="t('commons.confirmation.yes')" />
          <n-radio :value="0" :label="t('commons.confirmation.no')" />
        </n-space>
      </n-radio-group>
    </n-form-item>
    <n-form-item
      :label="t('commons.financial.balance', 1)"
      v-if="model.flags.showReverseTransactions === 1"
    >
      ${{ balance }}
    </n-form-item>
    <n-form-item
      :label="t('commons.financial.transaction', 2)"
      v-if="model.flags.showReverseTransactions === 1"
    >
      <n-ul style="text-align: left">
        <n-li
          v-for="c in issue?.rent.paymentsInfo.filter(
            (p) => getBalanceFromPaymentInfo(p) > 0
          )"
          :key="c.transactionId"
        >
          <span>
            <strong> {{ t("commons.financial.charge", 1) }} ({{ c.type }}) </strong>
            - ${{ c.amount }}
          </span>
          <n-ul>
            <n-li v-for="r in c.reverseHistory" :key="r.id">
              <span>
                <strong> {{ r.type }} </strong> - ${{ r.amount.value }}
              </span>
            </n-li>
          </n-ul>
        </n-li>
      </n-ul>
    </n-form-item>
    <n-form-item label=" " v-if="issue.rent.depositTransaction">
      <span class="mr-2">
        {{ t("issues.detail.tabs.actions.cancelRent.form.confirmationReverseDeposits") }}
      </span>
      <n-radio-group
        v-model:value="model.flags.showReverseDeposit"
        name="rgReverseDeposit"
      >
        <n-space>
          <n-radio :value="1" :label="t('commons.confirmation.yes')" />
          <n-radio :value="0" :label="t('commons.confirmation.no')" />
        </n-space>
      </n-radio-group>
    </n-form-item>
    <n-form-item
      :label="t('commons.financial.balance', 1)"
      v-if="model.flags.showReverseDeposit === 1"
    >
      ${{ getBalanceFromPaymentInfo(issue.rent.depositTransaction) }}
    </n-form-item>
    <n-form-item
      :label="t('commons.financial.transaction', 2)"
      v-if="
        model.flags.showReverseDeposit === 1 &&
        getBalanceFromPaymentInfo(issue.rent.depositTransaction) > 0
      "
    >
      <n-ul style="text-align: left">
        <n-li>
          <span>
            <strong>
              {{ t("commons.financial.charge", 1) }} ({{
                issue.rent.depositTransaction.type
              }})
            </strong>
            - ${{ issue.rent.depositTransaction.amount }}
          </span>
          <n-ul>
            <n-li v-for="r in issue.rent.depositTransaction.reverseHistory" :key="r.id">
              <span>
                <strong> {{ r.type }} </strong> - ${{ r.amount.value }}
              </span>
            </n-li>
          </n-ul>
        </n-li>
      </n-ul>
    </n-form-item>
    <n-space justify="end">
      <n-button type="Primary" @click="handleResolveIssue">
        {{ t("issues.detail.tabs.actions.buttonResolveIssue.text") }}
      </n-button>
    </n-space>
  </n-form>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import {
  NForm,
  NFormItem,
  NSpace,
  NRadio,
  NRadioGroup,
  NUl,
  NLi,
  NButton,
  useMessage,
} from "naive-ui";
import SummaryFormItems from "./Summary.vue";
import { useI18n } from "vue-i18n";
export default {
  props: {
    form_model: { type: Object, required: true },
    form_rules: { type: Object, required: true },
  },
  components: {
    NForm,
    SummaryFormItems,
    NFormItem,
    NSpace,
    NRadio,
    NRadioGroup,
    NUl,
    NLi,
    NButton,
  },
  emits: ["resolve"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const message = useMessage();
    const issue = computed(() => store.state.issues.selectedIssue);
    const balance = computed(() => store.getters["issues/balanceFromSelectedIssue"]);
    const modelRef = ref({
      resolution: props.form_model,
      flags: {
        showReverseTransactions: 0,
        showReverseDeposit: 0,
      },
    });
    modelRef.value.resolution.type = "ONE";
    const rules = ref({
      resolution: props.form_rules,
    });
    const formRef = ref(null);

    const getBalanceFromPaymentInfo = (p) => {
      let result = 0;
      if (p?.reverseHistory?.length > 0) {
        let total = 0;
        for (const rh of p.reverseHistory) {
          if (rh.type === "REVERSE") {
            total = p?.amount ?? 0;
            break;
          }
          total += parseFloat(rh.amount.value);
        }
        result = (p?.amount ?? 0) - total;
      } else {
        result = p?.amount ?? 0;
      }
      return +(Math.round(result + "e+2") + "e-2");
    };

    const reverseTransaction = async () => {
      await store.dispatch("app/lockUI");
      const txIds = [];
      for (const element of issue.value.rent.paymentsInfo.filter(
        (p) => getBalanceFromPaymentInfo(p) > 0 && p.status !== "PENDING"
      )) {
        try {
          await store.dispatch("payments/Reverse", {
            rentId: issue.value.rentId,
            transactionId: element.transactionId,
          });
          txIds.push(element.transactionId);
        } catch (error) {
          console.error(error);
          message.error(error);
        }
      }
      await store.dispatch("issues/GetIssue", issue.value.id);
      issue.value.rent.paymentsInfo
        .filter((p) => txIds.includes(p.transactionId))
        .forEach((element) => {
          modelRef.value.resolution.relatedTransactionsId = [
            ...modelRef.value.resolution.relatedTransactionsId,
            element.reverseHistory[element.reverseHistory.length - 1].id,
          ];
        });
      issue.value.rent.paymentsInfo
        .filter((p) => p.status === "PENDING")
        .forEach((element) => {
          element.status = "VOID";
        });

      await store.dispatch("rent/updateRent", {
        id: issue.value.rent.id,
        paymentsInfo: issue.value.rent.paymentsInfo,
        _version: issue.value.rent._version,
      });

      await store.dispatch("app/unlockUI");
    };

    const reverseDeposit = async () => {
      await store.dispatch("app/lockUI");
      if (issue.value.rent.depositTransaction) {
        try {
          if (issue.value.rent.depositTransaction.status === "PENDING") {
            await store.dispatch("issues/GetIssue", issue.value.id);
            issue.value.rent.depositTransaction.status = "VOID";
            issue.value.rent.depositTransaction.balance = 0;
            await store.dispatch("rent/updateRent", {
              id: issue.value.rent.id,
              depositTransaction: issue.value.rent.depositTransaction,
              _version: issue.value.rent._version,
            });
          } else {
            await store.dispatch("payments/Reverse", {
              rentId: issue.value.rentId,
              transactionId: issue.value.rent.depositTransaction.transactionId,
              isDeposit: true,
            });
            modelRef.value.resolution.relatedTransactionsId = [
              ...modelRef.value.resolution.relatedTransactionsId,
              issue.value.rent.depositTransaction.transactionId,
            ];
          }
        } catch (error) {
          console.error(error);
          message.error(error);
        }
      }
      await store.dispatch("app/unlockUI");
    };

    const handleResolveIssue = async (e) => {
      e.preventDefault();
      formRef.value?.validate(async (errors) => {
        console.log(errors);
        if (!errors) {
          if (issue.value.snapshotRentState === "PENDING") {
            modelRef.value.resolution.rentState = "CANCELLED";
          } else if (issue.value.snapshotRentState === "IN_PROCESS") {
            modelRef.value.resolution.rentState = "FINISHED";
          }

          if (modelRef.value.flags.showReverseTransactions === 1) {
            await reverseTransaction();
            await reverseDeposit();
          }

          emit("resolve", { resolution: modelRef.value.resolution });
        }
      });
    };
    return {
      t,
      model: modelRef,
      rules,
      formRef,
      issue,
      balance,
      handleResolveIssue,
      getBalanceFromPaymentInfo,
    };
  },
};
</script>
