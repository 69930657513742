<template>
  <div>
    <n-space vertical v-if="issue?.state === 'OPEN'">
      <n-button size="large" type="primary" block class="py-5" @click="takeIssue">
        <strong>{{ t("issues.buttonTakeIssue.text") }}</strong>
      </n-button>
    </n-space>
    <div v-else-if="issue?.state === 'IN_PROCESS'">
      <n-collapse accordion @item-header-click="handleItemHeaderClick">
        <n-collapse-item :title="t('issues.tabs.actions.cancelRent.label')" name="1">
          <case-one
            :form_model="model"
            :form_rules="rules"
            @resolve="handleResolve"
            v-if="['PENDING', 'IN_PROCESS'].includes(issue?.snapshotRentState)"
          />
          <div v-else>
            <p>
              {{
                t("issues.detail.tabs.actions.messages.noActionsInThisStates", {
                  states: "PENDING or IN_PROCESS",
                })
              }}
            </p>
          </div>
        </n-collapse-item>
        <n-collapse-item :title="t('issues.tabs.actions.changeDates.label')" name="2">
          <case-two
            :form_model="model"
            :form_rules="rules"
            @resolve="handleResolve"
            v-if="['PENDING'].includes(issue?.snapshotRentState)"
          />
          <div v-else>
            <p>
              {{
                t("issues.detail.tabs.actions.messages.noActionsInThisStates", {
                  states: "PENDING",
                })
              }}
            </p>
          </div>
        </n-collapse-item>
        <n-collapse-item
          :title="t('issues.tabs.actions.suspectedStolenTool.label')"
          name="3"
        >
          <case-three
            :form_model="model"
            :form_rules="rules"
            @resolve="handleResolve"
            v-if="['IN_PROCESS'].includes(issue?.snapshotRentState)"
          />
          <div v-else>
            <p>
              {{
                t("issues.detail.tabs.actions.messages.noActionsInThisStates", {
                  states: "IN_PROCESS",
                })
              }}
            </p>
          </div>
        </n-collapse-item>
        <n-collapse-item
          :title="t('issues.tabs.actions.disagreementClosing.label')"
          name="4"
        >
          <case-four
            :form_model="model"
            :form_rules="rules"
            @resolve="handleResolve"
            v-if="['FINISHED'].includes(issue?.snapshotRentState)"
          />
          <div v-else>
            <p>
              {{
                t("issues.detail.tabs.actions.messages.noActionsInThisStates", {
                  states: "FINISHED",
                })
              }}
            </p>
          </div>
        </n-collapse-item>
        <n-collapse-item
          :title="t('issues.tabs.actions.exceptionPaymentAdditionalDays.label')"
          name="5"
        >
          <case-five
            :form_model="model"
            :form_rules="rules"
            @resolve="handleResolve"
            v-if="['PENDING_FOR_PAYMENT'].includes(issue?.snapshotRentState)"
          />
          <div v-else>
            <p>
              {{
                t("issues.detail.tabs.actions.messages.noActionsInThisStates", {
                  states: "PENDING_FOR_PAYMENT",
                })
              }}
            </p>
          </div>
        </n-collapse-item>
        <n-collapse-item
          :title="t('issues.tabs.actions.disagreementOnReturnChecklist.label')"
          name="6"
        >
          <case-four
            :form_model="model"
            :form_rules="rules"
            :show_return_list="true"
            @resolve="handleResolve"
            v-if="['FINISHED'].includes(issue?.snapshotRentState)"
          />
          <div v-else>
            <p>
              {{
                t("issues.detail.tabs.actions.messages.noActionsInThisStates", {
                  states: "FINISHED",
                })
              }}
            </p>
          </div>
        </n-collapse-item>
      </n-collapse>
    </div>
  </div>
</template>
<script>
import { NButton, NSpace, NCollapse, NCollapseItem, useMessage } from "naive-ui";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { formatDates } from "@/shared/utils";
import CaseOne from "./CaseOne.vue";
import CaseTwo from "./CaseTwo.vue";
import CaseThree from "./CaseThree.vue";
import CaseFour from "./CaseFour.vue";
import CaseFive from "./CaseFive.vue";
import { useI18n } from "vue-i18n";
export default {
  components: {
    NButton,
    NSpace,
    NCollapse,
    NCollapseItem,
    CaseOne,
    CaseTwo,
    CaseThree,
    CaseFour,
    CaseFive,
  },
  emits: ["resolved"],
  setup(_props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const message = useMessage();
    const store = useStore();
    const issue = computed(() => store.state.issues.selectedIssue);

    const modelDef = {
      summary: null,
      message: t("issues.detail.tabs.actions.form.message.default"),
      datetime: formatDates(new Date()),
      rentState: null,
      type: null,
      relatedTransactionsId: [],
    };

    const rulesDef = {
      summary: {
        required: true,
        message: t("issues.detail.tabs.actions.form.summary.validation.required"),
        trigger: ["input"],
      },
      message: {
        required: true,
        message: t("issues.detail.tabs.actions.form.message.validation.required"),
        trigger: ["input"],
      },
    };

    const deepCopy = (item) => JSON.parse(JSON.stringify(item));

    const model = ref(deepCopy(modelDef));
    const rules = ref(deepCopy(rulesDef));

    const takeIssue = async () => {
      await store.dispatch("app/lockUI");
      await store.dispatch("issues/TakeIssue");
      await store.dispatch("app/unlockUI");
    };

    const handleItemHeaderClick = () => {
      model.value = deepCopy(modelDef);
      rules.value = deepCopy(rulesDef);
    };

    const handleResolve = async ({ resolution, rent }) => {
      await store.dispatch("app/lockUI");
      await store.dispatch("issues/SaveResolution", {
        resolution,
        userId: store.getters["auth/user_id"],
        rent,
      });
      await store.dispatch("app/unlockUI");
      message.success(t("issues.detail.tabs.actions.messages.success"));
      emit("resolved");
    };

    return {
      t,
      model,
      rules,
      issue,
      takeIssue,
      handleItemHeaderClick,
      handleResolve,
    };
  },
};
</script>
