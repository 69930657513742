<template>
  <n-h1>{{
    t("rent.checklist.resume.title", { checklistType: titleChecklistType })
  }}</n-h1>
  <div class="pb-4">
    <Carousel v-if="images.length > 0" :listFiles="images" show_arrow autoplay />
  </div>
  <div class="pb-4">
    <n-upload
      v-model:file-list="no_images"
      :show-remove-button="false"
      show-download-button
      @download="handleDownload"
    >
    </n-upload>
  </div>
  <n-table :bordered="true" :single-line="false" striped>
    <tbody>
      <tr v-for="field in checkListFields" :key="field.name">
        <td>{{ field.name }}</td>
        <td>
          {{
            field.type === "BOOLEAN"
              ? field.value === true
                ? t("commons.confirmation.yes")
                : t("commons.confirmation.no")
              : field.value
          }}
        </td>
      </tr>
    </tbody>
  </n-table>
</template>

<style lang="scss" scoped>
li {
  text-align: left;
}
</style>

<script>
import { NH1, NUpload, NTable } from "naive-ui";
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
import responsive from "@/mixins/responsive";
import { titleCase } from "title-case";
import { Storage } from "aws-amplify";
import { compareDesc } from "date-fns";
import mime from "mime";
import {
  getFileNameFromKey,
  getExtensionFromFileName,
  downloadBlob,
} from "@/shared/utils";
import Carousel from "@/components/Carousel.vue";
import { useI18n } from "vue-i18n";

export default {
  name: "ChecklistResume",
  components: {
    NH1,
    NTable,
    Carousel,
    NUpload,
  },
  props: {
    modelValue: Object,
    checklistType: String,
    bucket: String,
    ownerId: String,
  },
  mixins: [responsive],
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const checkListFieldsRef = ref(props.modelValue);
    const store = useStore();
    const listFilesRef = ref([]);

    onMounted(async () => {
      store.dispatch("app/lockUI");
      const files = await Storage.list(`${props.bucket}`, {
        level: "protected",
        identityId: props.ownerId,
      });
      if (files.length > 0) {
        files.sort((a, b) => {
          return compareDesc(b.lastModified, a.lastModified);
        });

        for (const file of files) {
          const fileType = mime.getType(getExtensionFromFileName(file.key));
          listFilesRef.value.push({
            id: file.key,
            name: getFileNameFromKey(file.key),
            status: "finished",
            url: await getUrlFile(file.key),
            type: fileType,
            lastModified: file.lastModified,
          });
        }
      }
      store.dispatch("app/unlockUI");
    });

    const getUrlFile = async (fileId) => {
      return Storage.get(fileId, {
        level: "protected",
        identityId: props.ownerId,
      });
    };

    return {
      t,
      checkListFields: checkListFieldsRef.value.filter(
        (checklistField) => !checklistField.hidden
      ),
      titleChecklistType: computed(() =>
        titleCase(`${props.checklistType.toLowerCase()}`)
      ),
      listFiles: listFilesRef,
      images: computed(() => {
        return listFilesRef.value.filter((file) => file.type.startsWith("image"));
      }),
      no_images: computed(() => {
        return listFilesRef.value.filter((file) => !file.type.startsWith("image"));
      }),
      handleDownload: async (file) => {
        console.debug("Downloading file", file);
        store.dispatch("app/lockUI");
        const result = await Storage.get(file.id, {
          download: true,
          level: "protected",
          identityId: props.ownerId,
        });
        downloadBlob(result.Body, file.name);
        store.dispatch("app/unlockUI");
      },
    };
  },
};
</script>
