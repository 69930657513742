<template>
  <n-form
    ref="formRef"
    :label-width="100"
    :model="model"
    :rules="rules"
    label-placement="left"
  >
    <summary-form-items v-model="model.resolution" />
    <n-form-item label=" " v-if="show_return_list">
      <checklist-resume
        v-model="issue.rent.deliveryCheckList"
        checklistType="RETURN"
        :bucket="filesBucket"
        :ownerId="issue?.rent.publication.user"
      />
    </n-form-item>
    <div
      v-if="
        issue?.rent.depositTransaction != null ||
        issue?.rent.depositTransaction != undefined
      "
    >
      <div
        v-if="
          ['PENDING', 'AUTHORIZED', 'HOLD', 'AUTHORIZATION_FAILED'].includes(
            issue?.rent.depositTransaction.status
          )
        "
      >
        <n-form-item label=" ">
          <span class="mr-2">
            {{
              t(
                "issues.detail.tabs.actions.disagreementClosing.form.confirmationProcessDeposit",
                {
                  depositAmount: issue.rent.deposit,
                }
              )
            }}
          </span>
          <n-radio-group
            v-model:value="model.flags.processDepositTransacions"
            name="rgProcessTx"
          >
            <n-space>
              <n-radio :value="1" :label="t('commons.confirmation.yes')" />
              <n-radio :value="0" :label="t('commons.confirmation.no')" />
            </n-space>
          </n-radio-group>
        </n-form-item>
        <n-form-item label=" " v-if="model.flags.processDepositTransacions === 0">
          <span class="mr-2">
            {{
              t(
                "issues.detail.tabs.actions.disagreementClosing.form.confirmationProcessPartialDepositAmount"
              )
            }}
          </span>
          <n-radio-group
            v-model:value="model.flags.processPartialDepositTransacions"
            name="rgPartialProcessTx"
          >
            <n-space>
              <n-radio :value="1" :label="t('commons.confirmation.yes')" />
              <n-radio :value="0" :label="t('commons.confirmation.no')" />
            </n-space>
          </n-radio-group>
        </n-form-item>
        <n-form-item
          :label="
            t('issues.detail.tabs.actions.disagreementClosing.form.amountToRefund.label')
          "
          path="amount"
          v-if="model.flags.processPartialDepositTransacions === 1"
        >
          <n-input-number
            v-model:value="model.amount"
            :placeholder="
              t(
                'issues.detail.tabs.actions.disagreementClosing.form.amountToRefund.placeholder'
              )
            "
            :min="0.01"
            :max="getBalanceFromDepositTransaction()"
          />
        </n-form-item>
      </div>
      <div v-else>
        <n-form-item label=" ">
          <span class="mr-2">
            {{
              t(
                "issues.detail.tabs.actions.disagreementClosing.form.confirmationReverseDeposit",
                {
                  depositAmount: issue.rent.deposit,
                }
              )
            }}
          </span>
          <n-radio-group
            v-model:value="model.flags.reverseDepositTransactions"
            name="rgReverseTx"
          >
            <n-space>
              <n-radio :value="1" :label="t('commons.confirmation.yes')" />
              <n-radio :value="0" :label="t('commons.confirmation.no')" />
            </n-space>
          </n-radio-group>
        </n-form-item>
        <n-form-item label=" " v-if="model.flags.reverseDepositTransactions === 0">
          <span class="mr-2">
            {{
              t(
                "issues.detail.tabs.actions.disagreementClosing.form.confirmationRefundPartialDepositAmount"
              )
            }}
          </span>
          <n-radio-group
            v-model:value="model.flags.refundDepositTransactions"
            name="rgRefundTx"
          >
            <n-space>
              <n-radio :value="1" :label="t('commons.confirmation.yes')" />
              <n-radio :value="0" :label="t('commons.confirmation.no')" />
            </n-space>
          </n-radio-group>
        </n-form-item>
        <n-form-item
          :label="
            t('issues.detail.tabs.actions.disagreementClosing.form.amountToRefund.label')
          "
          path="amount"
          v-if="model.flags.refundDepositTransactions === 1"
        >
          <n-input-number
            v-model:value="model.amount"
            :placeholder="
              t(
                'issues.detail.tabs.actions.disagreementClosing.form.amountToRefund.placeholder'
              )
            "
            :min="0.01"
            :max="getBalanceFromDepositTransaction()"
          />
        </n-form-item>
      </div>
    </div>
    <div class="mb-3" v-else>
      <n-alert title="Warning" type="warning">
        {{ t("issues.detail.tabs.actions.disagreementClosing.messages.noDepositPayed") }}
      </n-alert>
    </div>
    <n-space justify="end">
      <n-button type="Primary" @click="handleResolveIssue">
        {{ t("issues.detail.tabs.actions.buttonResolveIssue.text") }}
      </n-button>
    </n-space>
  </n-form>
</template>
<script>
import { ref, computed, watchEffect } from "vue";
import { useStore } from "vuex";
import {
  NForm,
  NSpace,
  NButton,
  NFormItem,
  NRadioGroup,
  NRadio,
  NAlert,
  NInputNumber,
  useMessage,
} from "naive-ui";
import SummaryFormItems from "./Summary.vue";
import ChecklistResume from "@/components/rent/Checklist/ChecklistResume.vue";
import { useI18n } from "vue-i18n";
export default {
  props: {
    form_model: { type: Object, required: true },
    form_rules: { type: Object, required: true },
    show_return_list: { type: Boolean, default: () => false },
  },
  components: {
    NForm,
    SummaryFormItems,
    NSpace,
    NButton,
    NFormItem,
    NRadioGroup,
    NRadio,
    NAlert,
    NInputNumber,
    ChecklistResume,
  },
  emits: ["resolve"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const message = useMessage();
    const issue = computed(() => store.state.issues.selectedIssue);
    const modelRef = ref({
      resolution: props.form_model,
      flags: {
        processDepositTransacions: 0,
        processPartialDepositTransacions: 0,
        reverseDepositTransactions: 0,
        refundDepositTransactions: 0,
      },
      amount: 0,
    });
    modelRef.value.resolution.type = "FOUR";
    modelRef.value.resolution.rentState = "FINISHED";
    const rules = ref({
      resolution: props.form_rules,
      amount: [
        {
          required: true,
          type: "number",
          message: t("commons.from.validation.required"),
          trigger: ["input"],
        },
        {
          message: t("commons.from.validation.invalidFormat"),
          trigger: ["input"],
          transform(value) {
            return parseFloat(value);
          },
          validator(_rule, value) {
            const balance = getBalanceFromDepositTransaction();
            return value > 0 && value <= balance;
          },
        },
      ],
    });
    const formRef = ref(null);

    watchEffect(() => {
      if (modelRef.value.flags.reverseDepositTransactions === 1) {
        modelRef.value.flags.refundDepositTransactions = 0;
      }
      if (modelRef.value.flags.refundDepositTransactions === 0) {
        modelRef.value.amount = 0;
      }
    });

    watchEffect(() => {
      if (modelRef.value.flags.processDepositTransacions === 1) {
        modelRef.value.flags.processPartialDepositTransacions = 0;
      }
      if (modelRef.value.flags.processPartialDepositTransacions === 0) {
        modelRef.value.amount = 0;
      }
    });

    const getBalanceFromDepositTransaction = () => {
      const dTx = issue.value?.rent?.depositTransaction;
      let result = 0;
      if (dTx?.reverseHistory?.length > 0) {
        let total = 0;
        for (const rh of dTx.reverseHistory) {
          total += parseFloat(rh.amount.value);
        }
        result = (dTx?.amount ?? 0) - total;
      } else {
        result = dTx?.amount ?? 0;
      }
      return +(Math.round(result + "e+2") + "e-2");
    };

    const reverseDeposit = async () => {
      const dTx = issue.value.rent.depositTransaction;
      const oldBalance = dTx.balance;
      try {
        dTx.balance = 0;
        await store.dispatch("rent/updateRent", {
          id: issue.value.rent.id,
          depositTransaction: dTx,
          _version: issue.value.rent._version,
        });
        await store.dispatch("payments/Reverse", {
          rentId: issue.value.rentId,
          transactionId: dTx.transactionId,
        });
        const profitInfo = {
          description: `Withdrawal for deposit rent of tool ${issue.value.rent.publication.title}`,
          amount: -dTx.amount,
          user: issue.value.rent.publication.user,
          rentId: issue.value.rent.id,
        };
        await store.dispatch(
          "maintainer_balance/addProfitTransactionToBalance",
          profitInfo
        );
      } catch (error) {
        dTx.balance = oldBalance;
        await store.dispatch("rent/updateRent", {
          id: issue.value.rent.id,
          depositTransaction: dTx,
          _version: issue.value.rent._version + 1,
        });
        console.log(error);
        message.error(error);
        throw error;
      }
    };

    const refundDeposit = async () => {
      const dTx = issue.value.rent.depositTransaction;
      const oldBalance = dTx.balance;
      try {
        dTx.balance = oldBalance - modelRef.value.amount;
        await store.dispatch("rent/updateRent", {
          id: issue.value.rent.id,
          depositTransaction: dTx,
          _version: issue.value.rent._version,
        });
        await store.dispatch("payments/Refund", {
          rentId: issue.value.rentId,
          transactionId: dTx.transactionId,
          amount: modelRef.value.amount,
        });
        const profitInfo = {
          description: `Withdrawal for deposit rent of tool ${issue.value.rent.publication.title}`,
          amount: -modelRef.value.amount,
          user: issue.value.rent.publication.user,
          rentId: issue.value.rent.id,
        };
        await store.dispatch(
          "maintainer_balance/addProfitTransactionToBalance",
          profitInfo
        );
      } catch (error) {
        dTx.balance = oldBalance;
        await store.dispatch("rent/updateRent", {
          id: issue.value.rent.id,
          depositTransaction: dTx,
          _version: issue.value.rent._version + 1,
        });
        console.log(error);
        message.error(error);
        throw error;
      }
    };

    const processDeposit = async () => {
      try {
        await store.dispatch("payments/CaptureDeposit", issue.value.rent);
        const profitInfo = {
          description: `Profit for deposit rent of tool ${issue.value.rent.publication.title}`,
          amount: issue.value.rent.deposit,
          user: issue.value.rent.publication.user,
          rentId: issue.value.rent.id,
        };
        await store.dispatch(
          "maintainer_balance/addProfitTransactionToBalance",
          profitInfo
        );
      } catch (error) {
        message.error("Error holding deposit");
      }
    };

    const processPartialDeposit = async () => {
      await processDeposit();
      const dTx = issue.value.rent.depositTransaction;
      await store.dispatch("payments/createQueueDepositRefund", {
        transactionId: dTx.transactionId,
        amount: (dTx.balance ?? dTx.amount) - modelRef.value.amount,
        rentId: issue.value.rent.id,
        issueId: issue.value.id,
      });
      message.success(
        t("issues.detail.tabs.actions.disagreementClosing.messages.refundQueued")
      );
    };

    const handleResolveIssue = async (e) => {
      e.preventDefault();
      formRef.value?.validate(async (errors) => {
        console.log(errors);
        if (!errors) {
          if (modelRef.value.flags.reverseDepositTransactions === 1) {
            await reverseDeposit();
          } else if (modelRef.value.flags.refundDepositTransactions === 1) {
            await refundDeposit();
          } else if (modelRef.value.flags.processDepositTransacions === 1) {
            await processDeposit();
          } else if (modelRef.value.flags.processPartialDepositTransacions === 1) {
            await processPartialDeposit();
          }

          emit("resolve", { resolution: modelRef.value.resolution });
        }
      });
    };
    return {
      t,
      model: modelRef,
      rules,
      formRef,
      issue,
      handleResolveIssue,
      getBalanceFromDepositTransaction,
      filesBucket: computed(() => {
        return `rent/${issue?.value.rent.id}/checklist/RETURN`;
      }),
    };
  },
};
</script>
