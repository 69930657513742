<template>
  <div>
    <n-form-item
      :label="t('issues.detail.tabs.actions.form.summary.label')"
      path="resolution.summary"
    >
      <n-input
        v-model:value="model.summary"
        type="textarea"
        :placeholder="t('Cissues.detail.tabs.actions.form.summary.placeholder')"
      />
    </n-form-item>
    <n-form-item
      :label="t('issues.detail.tabs.actions.form.message.label')"
      path="resolution.message"
    >
      <n-input
        v-model:value="model.message"
        type="text"
        :placeholder="t('Cissues.detail.tabs.actions.form.message.placeholder')"
      />
    </n-form-item>
  </div>
</template>
<script>
import { NFormItem, NInput } from "naive-ui";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
export default {
  components: {
    NFormItem,
    NInput,
  },
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const modelRef = ref(props.modelValue);
    watch(modelRef, (newModelRef) => {
      emit("update:modelValue", newModelRef.value);
    });
    watch(props, (p) => (modelRef.value = p.modelValue));
    return {
      t,
      model: modelRef,
    };
  },
};
</script>
