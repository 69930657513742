<template>
  <n-form
    ref="formRef"
    :label-width="100"
    :model="model"
    :rules="rules"
    label-placement="left"
  >
    <summary-form-items v-model="model.resolution" />
    <n-form-item label=" ">
      <span class="mr-2">{{
        t("issues.detail.tabs.actions.exceptionPaymentAdditionalDays.form.confirmation")
      }}</span>
      <n-radio-group v-model:value="model.flags.skipCharge" name="rgSkipCharge">
        <n-space>
          <n-radio :value="1" label="Yes" />
          <n-radio :value="0" label="No" />
        </n-space>
      </n-radio-group>
    </n-form-item>
    <n-space justify="end">
      <n-button type="Primary" @click="handleResolveIssue"
        >{{ t("issues.detail.tabs.actions.buttonResolveIssue.text") }}
      </n-button>
    </n-space>
  </n-form>
</template>
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { NForm, NSpace, NButton, NRadio, NRadioGroup, NFormItem } from "naive-ui";
import SummaryFormItems from "./Summary.vue";
import { useI18n } from "vue-i18n";
export default {
  props: {
    form_model: { type: Object, required: true },
    form_rules: { type: Object, required: true },
  },
  components: {
    NForm,
    SummaryFormItems,
    NSpace,
    NButton,
    NRadio,
    NRadioGroup,
    NFormItem,
  },
  emits: ["resolve"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const issue = computed(() => store.state.issues.selectedIssue);
    const modelRef = ref({
      resolution: props.form_model,
      flags: {
        skipCharge: 0,
      },
    });
    modelRef.value.resolution.type = "FIVE";
    const rules = ref({
      resolution: props.form_rules,
    });
    const formRef = ref(null);

    const handleResolveIssue = async (e) => {
      e.preventDefault();
      formRef.value?.validate(async (errors) => {
        console.log(errors);
        if (!errors) {
          if (modelRef.value.flags.skipCharge > 0) {
            modelRef.value.resolution.rentState = "FINISHED";
          } else {
            modelRef.value.resolution.rentState = issue.value.snapshotRentState;
          }
          emit("resolve", { resolution: modelRef.value.resolution });
        }
      });
    };
    return {
      t,
      model: modelRef,
      rules,
      formRef,
      issue,
      handleResolveIssue,
    };
  },
};
</script>
