<template>
  <n-grid cols="1 s:8" responsive="screen" style="padding-top: 30px">
    <n-gi span="1 s:5" offset="0 s:1">
      <n-tabs type="segment" animated v-model:value="currentTab">
        <n-tab-pane name="issue" :tab="t('issues.detail.tabs.issue.label')">
          <detail />
        </n-tab-pane>
        <n-tab-pane
          name="rent"
          :tab="t('issues.detail.tabs.rent.title')"
          display-directive="show:lazy"
          v-if="issue?.rentId"
        >
          <rent-chat :id="issue.rentId" />
        </n-tab-pane>
        <n-tab-pane
          name="actions"
          :tab="t('issues.detail.tabs.actions.title')"
          :disabled="issue?.state === 'CLOSED'"
        >
          <actions @resolved="handleResolve" />
        </n-tab-pane>
      </n-tabs>
    </n-gi>
  </n-grid>
</template>
<script>
import { NTabs, NTabPane, NGrid, NGi } from "naive-ui";
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import RentChat from "../maintainers/rents/Chat.vue";
import Detail from "../../components/issues/Detail.vue";
import Actions from "../../components/issues/Actions.vue";
import { useI18n } from "vue-i18n";
export default {
  components: { NTabs, NTabPane, NGrid, NGi, Detail, Actions, RentChat },
  setup(_props, { attrs }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const issue = computed(() => store.state.issues.selectedIssue);
    const relatedUsers = computed(() => store.state.issues.emailRelatedUsers);
    const currentTab = ref("issue");
    onMounted(async () => {
      await store.dispatch("app/lockUI");
      await store.dispatch("issues/GetIssue", attrs.id);
      await store.dispatch("issues/AddFixedUrlsToEvidences");
      await store.dispatch("app/unlockUI");
    });
    const handleResolve = () => {
      currentTab.value = "issue";
    };
    return {
      t,
      issue,
      relatedUsers,
      currentTab,
      handleResolve,
    };
  },
};
</script>
