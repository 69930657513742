<template>
  <n-form
    ref="formRef"
    :label-width="100"
    :model="model"
    :rules="rules"
    label-placement="left"
  >
    <summary-form-items v-model="model.resolution" />
    <n-form-item
      ref="dateSelectorRef"
      :label="t('commons.labels.date', 2)"
      path="rent.requestedRangeOfDates"
    >
      <range-dates-selector
        v-if="model?.rent?.publication"
        :publication="model.rent.publication"
        v-model:value="model.rent.requestedRangeOfDates"
        :ignoreRentId="model.rent.id"
        @rangechanged="handleRangeChanged"
        hideTitle
      />
    </n-form-item>
    <n-space justify="end">
      <n-button type="Primary" @click="handleResolveIssue">
        {{ t("issues.detail.tabs.actions.buttonResolveIssue.text") }}
      </n-button>
    </n-space>
  </n-form>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { NForm, NFormItem, NSpace, NButton, useDialog, useMessage } from "naive-ui";
import RangeDatesSelector from "@/components/rent/RangeDatesSelector.vue";
import SummaryFormItems from "./Summary.vue";
import { useI18n } from "vue-i18n";
export default {
  props: {
    form_model: { type: Object, required: true },
    form_rules: { type: Object, required: true },
  },
  components: {
    NForm,
    SummaryFormItems,
    NFormItem,
    NSpace,
    NButton,
    RangeDatesSelector,
  },
  emits: ["resolve"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const dialog = useDialog();
    const message = useMessage();
    const store = useStore();
    const issue = computed(() => store.state.issues.selectedIssue);
    const formRef = ref(null);
    const dateSelectorRef = ref(null);
    const originalRangeDate = {
      start: null,
      end: null,
    };
    const modelRef = ref({
      resolution: props.form_model,
      rent: null,
    });
    modelRef.value.resolution.type = "TWO";
    modelRef.value.resolution.rentState = issue.value.snapshotRentState;
    const rules = ref({
      resolution: props.form_rules,
      rent: {
        requestedRangeOfDates: [
          {
            message: t("commons.form.validation.rangeOfDatesRequired"),
            trigger: ["date-change"],
            validator: (_rule, value) => !!value,
          },
          {
            message: "",
            trigger: ["date-change"],
            validator: (_rule, value) =>
              getDiffInDays(value) <= getDiffInDays(originalRangeDate),
          },
        ],
      },
    });

    onMounted(async () => {
      await store.dispatch("app/lockUI");
      store.dispatch("rent/getRentById", issue.value.rentId).then(async (r) => {
        originalRangeDate.start = r.requestedRangeOfDates.start;
        originalRangeDate.end = r.requestedRangeOfDates.end;
        const maxDays = getDiffInDays(originalRangeDate);
        rules.value.rent.requestedRangeOfDates[1].message = t(
          "commons.form.validation.maxSelectedDaysReached",
          { maxDays: maxDays }
        );

        modelRef.value.rent = r;
        await store.dispatch("app/unlockUI");
      });
    });

    const getDiffFromDates = (date1, date2) => {
      const diff = date1 - date2;
      return Math.ceil(diff / (1000 * 3600 * 24)) + 1;
    };

    const getDiffInDays = (rangeDate) => {
      if (rangeDate) {
        return getDiffFromDates(rangeDate.end, rangeDate.start);
      }
      return null;
    };

    const handleRangeChanged = () => {
      setTimeout(() => dateSelectorRef.value?.validate({ trigger: "date-change" }), 500);
    };

    const getTransactionWithFunds = (refund) => {
      for (const p of modelRef.value.rent.paymentsInfo) {
        if (p.amount >= refund) {
          return p;
        }
      }
      return null;
    };

    const processRefund = async (txToRefund, totalRefund) => {
      try {
        await store.dispatch("payments/Refund", {
          rentId: modelRef.value.rent.id,
          transactionId: txToRefund,
          amount: totalRefund,
        });
      } catch (error) {
        console.log(error);
        message.error(error);
        throw error;
      }
      modelRef.value.resolution.relatedTransactionsId = [
        ...modelRef.value.resolution.relatedTransactionsId,
        txToRefund,
      ];
    };

    const processReverse = async (txToReverse) => {
      try {
        await store.dispatch("payments/Reverse", {
          rentId: issue.value.rentId,
          transactionId: txToReverse.transactionId,
        });
      } catch (error) {
        console.log(error);
        message.error(error);
        throw error;
      }
    };

    const processNewTx = async (originalTx, modeTx) => {
      try {
        const response = await store.dispatch("payments/Process", {
          rent: modelRef.value.rent,
          publication: issue.value.rent.publication,
          amount: modelRef.value.rent.totalCost,
          paymentType: "INITIAL",
          mode: modeTx,
          paymentMethod: {
            details: {
              bin: originalTx.bin,
              cardType: originalTx.cardType,
              lastFour: originalTx.lastFour,
            },
          },
        });
        console.log(response);
      } catch (error) {
        message.error("Error holding rent");
        store.dispatch("notifications/FireNotification", {
          userId: modelRef.value.rent.user,
          fromUserId: modelRef.value.rent.publication.user,
          title: t(
            "issues.detail.tabs.actions.changeDates.notifications.depositIssue.title"
          ),
          message: t(
            "issues.detail.tabs.actions.changeDates.notifications.depositIssue.message"
          ),
          avatar: {
            publicationID: modelRef.value.rent.publication.id,
            publicationUser: modelRef.value.rent.publication.user,
            defaultImage: modelRef.value.rent.publication.orderPhotos[0],
          },
          linkTitle: t(
            "issues.detail.tabs.actions.changeDates.notifications.depositIssue.linkTitle"
          ),
          targetRoute: {
            name: "rent",
            params: [
              {
                key: "rentId",
                value: modelRef.value.rent.id,
              },
              {
                key: "publicationId",
                value: modelRef.value.rent.publication.id,
              },
            ],
          },
        });
        throw error;
      }
    };

    const getRentWithNewDates = async () => {
      const refundedRent = await store.dispatch("rent/getRentById", issue.value.rentId);
      refundedRent.requestedRangeOfDates = modelRef.value.rent.requestedRangeOfDates;
      refundedRent.requestedDays = modelRef.value.rent.requestedDays;
      return store.dispatch("rent/calculateTotals", {
        rent: refundedRent,
        publication: refundedRent.publication,
        originalRangeOfDates: originalRangeDate,
      });
    };

    const handleResolveIssue = async (e) => {
      e.preventDefault();
      formRef.value?.validate(async (errors) => {
        console.log(errors);
        if (!errors) {
          let finalRent = null;
          await store.dispatch("app/lockUI");
          const newRequestedDays = getDiffInDays(
            modelRef.value.rent.requestedRangeOfDates
          );
          const diffDays = getDiffInDays(originalRangeDate) - newRequestedDays;
          modelRef.value.rent.requestedDays = newRequestedDays;
          if (diffDays > 0) {
            await store.dispatch("rent/calculateTotals", {
              rent: modelRef.value.rent,
              publication: modelRef.value.rent.publication,
              originalRangeOfDates: originalRangeDate,
            });

            const totalRefund = Math.abs(modelRef.value.rent.pendingToPay);
            const txToRefund = getTransactionWithFunds(totalRefund);

            if (txToRefund?.status === "SETTLED") {
              await processRefund(txToRefund.transactionId, totalRefund);
            } else if (txToRefund?.status === "PENDING") {
              txToRefund.amount = modelRef.value.rent.totalCost;
              txToRefund.taxAmount = modelRef.value.rent.tax;
              await store.dispatch("rent/updateRent", {
                id: modelRef.value.rent.id,
                paymentsInfo: modelRef.value.rent.paymentsInfo,
                _version: modelRef.value.rent._version,
              });
            } else if (
              ["AUTHORIZED", "SUBMITTED_FOR_SETTLEMENT"].includes(txToRefund?.status)
            ) {
              await processReverse(txToRefund.transactionId);
              const dayDiff = getDiffFromDates(new Date(), newRequestedDays.start);
              const modeTx = dayDiff > 1 ? "STORE_REF" : dayDiff > 0 ? "HOLD" : "PAYMENT";
              await processNewTx(txToRefund, modeTx);
            }

            if (txToRefund) {
              finalRent = await getRentWithNewDates();
              delete finalRent.paymentsInfo;
            } else {
              await store.dispatch("app/unlockUI");
              dialog.warning({
                title: t(
                  "issues.detail.tabs.actions.changeDates.dialogs.noTransactionsFound.title"
                ),
                closable: false,
                maskClosable: false,
                content: t(
                  "issues.detail.tabs.actions.changeDates.dialogs.noTransactionsFound.content"
                ),
                positiveText: t("commons.actions.continue"),
              });
              return;
            }
          } else if (diffDays === 0) {
            finalRent = await getRentWithNewDates();
            delete finalRent.paymentsInfo;
          }
          await store.dispatch("app/unlockUI");
          emit("resolve", {
            resolution: modelRef.value.resolution,
            rent: finalRent,
          });
        }
      });
    };
    return {
      t,
      model: modelRef,
      rules,
      formRef,
      dateSelectorRef,
      issue,
      handleResolveIssue,
      handleRangeChanged,
    };
  },
};
</script>
