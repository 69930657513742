<template>
  <n-card
    :title="
      t('issues.detail.tabs.issue.title', {
        publicationTitle: issue?.rent?.publication?.title,
      })
    "
  >
    <n-divider title-placement="left">
      {{ t("issues.detail.tabs.issue.information.title") }}
    </n-divider>
    <n-descriptions label-placement="left">
      <n-descriptions-item
        :label="t('issues.detail.tabs.issue.information.labels.state')"
      >
        {{ issue?.state }}
      </n-descriptions-item>
      <n-descriptions-item
        :label="t('issues.detail.tabs.issue.information.labels.previousRentState')"
      >
        {{ issue?.snapshotRentState }}
      </n-descriptions-item>
      <n-descriptions-item
        :label="t('issues.detail.tabs.issue.information.labels.creationDate')"
      >
        {{ issue?.datetime }}
      </n-descriptions-item>
      <n-descriptions-item
        :label="t('issues.detail.tabs.issue.information.labels.subject')"
      >
        {{ issue?.subject }}
      </n-descriptions-item>
      <n-descriptions-item
        :label="t('issues.detail.tabs.issue.information.labels.description')"
      >
        {{ issue?.description }}
      </n-descriptions-item>
    </n-descriptions>
    <n-divider title-placement="left">
      {{ t("issues.detail.tabs.issue.usersInformation.title") }}
    </n-divider>
    <n-descriptions label-placement="top">
      <n-descriptions-item v-for="(x, i) in relatedUsers" :key="i">
        <n-descriptions
          label-placement="left"
          :column="1"
          :title="
            x.id === issue?.rent?.user
              ? t('commons.persons.renter')
              : t('commons.persons.owner')
          "
        >
          <n-descriptions-item
            :label="t('issues.detail.tabs.issue.usersInformation.labels.email')"
          >
            {{ x.email }}
          </n-descriptions-item>
          <n-descriptions-item
            :label="t('issues.detail.tabs.issue.usersInformation.labels.name')"
          >
            {{ x.name }}
          </n-descriptions-item>
          <n-descriptions-item
            :label="t('issues.detail.tabs.issue.usersInformation.labels.lastName')"
          >
            {{ x.familyName }}
          </n-descriptions-item>
          <n-descriptions-item
            :label="t('issues.detail.tabs.issue.usersInformation.labels.phone')"
          >
            {{ x.phoneNumber }}
          </n-descriptions-item>
        </n-descriptions>
      </n-descriptions-item>
    </n-descriptions>
    <n-divider title-placement="left">
      {{ t("issues.detail.tabs.issue.evidences.title", 2) }}
    </n-divider>
    <div v-if="issue?.evidences?.length > 0">
      <n-card v-for="e in issue.evidences" :key="e.id" hoverable class="m-2">
        <n-thing>
          <template #header>
            {{ e.subject }}
          </template>
          <template #header-extra>
            {{ t("issues.detail.tabs.issue.evidences.title", 1) }} #{{ e.id }}
          </template>
          <template #description>
            <div style="text-align: left">
              {{ t("issues.detail.tabs.issue.evidences.from", { date: e.datetime }) }}
            </div>
          </template>
          {{ e.description }}
          <template #footer
            ><div style="text-align: left">
              {{ t("issues.detail.tabs.issue.evidences.by", { user: e.owner }) }}
            </div></template
          >
          <template v-if="e.fixedKeys?.length > 0" #action>
            <div style="text-align: left">
              {{ t("issues.detail.tabs.issue.evidences.files") }}:
              <n-ul>
                <n-li v-for="(k, i) in e.fixedKeys" :key="i">
                  <a :href="k.url" target="_blank">{{ k.displayName }}</a>
                </n-li>
              </n-ul>
            </div></template
          >
        </n-thing>
      </n-card>
    </div>
    <div v-else>
      <p>{{ t("issues.detail.tabs.issue.evidences.noEvidences") }}</p>
    </div>
    <n-divider title-placement="left">
      {{ t("issues.detail.tabs.issue.transactions.title") }}
    </n-divider>
    <small>
      &nbsp;{{ t("issues.detail.tabs.issue.transactions.labels.balance") }}:&nbsp;
    </small>
    <n-text :type="balance < 0 ? 'error' : 'default'"> $ {{ balance }}</n-text>
    <n-list
      bordered
      v-for="c in issue?.rent.paymentsInfo"
      :key="c.transactionId"
      hoverable
      class="m-2"
    >
      <template #header>
        <n-space justify="space-between">
          <span>
            <n-tooltip trigger="hover">
              <template #trigger>
                <div>
                  <strong>{{
                    t("issues.detail.tabs.issue.transactions.labels.charge")
                  }}</strong>
                  - ${{ c.amount }}
                </div>
              </template>
              {{ c.transactionId }}
            </n-tooltip>
          </span>
          <small
            >{{ t("issues.detail.tabs.issue.transactions.labels.registeredAt") }}:
            {{ new Date(c.date) }}</small
          >
        </n-space>
      </template>
      <n-list-item v-for="r in c.reverseHistory" :key="r.id">
        <n-space justify="space-between">
          <span>
            <n-tooltip trigger="hover">
              <template #trigger>
                <div v-if="r.type === 'REVERSE'">
                  <strong>{{
                    t("issues.detail.tabs.issue.transactions.labels.reverseTypes.reverse")
                  }}</strong>
                </div>
                <div v-else>
                  <strong>{{
                    t("issues.detail.tabs.issue.transactions.labels.reverseTypes.refund")
                  }}</strong>
                  - ${{ r.amount.value }}
                </div>
              </template>
              {{ r.id }}
            </n-tooltip>
          </span>
          <small>from: {{ new Date(r.date) }}</small>
        </n-space>
      </n-list-item>
    </n-list>
    <n-divider title-placement="left">
      {{ t("issues.detail.tabs.issue.resolution.title") }}
    </n-divider>
    <div v-if="issue?.resolution">
      <n-thing>
        <template #description>
          <div style="text-align: left">
            {{ t("issues.detail.tabs.issue.transactions.labels.registeredAt") }}:
            {{ issue?.resolution?.datetime ?? "-" }}
          </div>
        </template>
        {{ issue?.resolution?.summary ?? "-" }}
        <template #footer>
          <div style="text-align: left">
            {{ t("issues.detail.tabs.issue.resolution.labels.messageToUsers") }}:
            {{ issue?.resolution?.message ?? "-" }}
          </div>
        </template>
        <template v-if="issue?.resolution?.relatedTransactionsId?.length > 0" #action>
          <div style="text-align: left">
            {{ t("issues.detail.tabs.issue.resolution.labels.relatedTransactionId") }}:
            <n-ul>
              <n-li v-for="(k, i) in issue?.resolution.relatedTransactionsId" :key="i">
                {{ k }}
              </n-li>
            </n-ul>
          </div></template
        >
      </n-thing>
    </div>
    <div v-else>
      <p>{{ t("issues.detail.tabs.issue.resolution.messages.noResolution") }}</p>
    </div>
  </n-card>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import {
  NCard,
  NDivider,
  NThing,
  NUl,
  NLi,
  NDescriptions,
  NDescriptionsItem,
  NList,
  NListItem,
  NSpace,
  NTooltip,
  NText,
} from "naive-ui";
import { useI18n } from "vue-i18n";
export default {
  components: {
    NCard,
    NDivider,
    NThing,
    NUl,
    NLi,
    NDescriptions,
    NDescriptionsItem,
    NList,
    NListItem,
    NSpace,
    NTooltip,
    NText,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const issue = computed(() => store.state.issues.selectedIssue);
    const relatedUsers = computed(() => store.state.issues.emailRelatedUsers);
    const balance = computed(() => store.getters["issues/balanceFromSelectedIssue"]);

    return {
      t,
      issue,
      relatedUsers,
      balance,
    };
  },
};
</script>
