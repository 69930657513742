<template>
  <div>
    <n-carousel
      :show-arrow="show_arrow"
      :autoplay="autoplay"
      style="width: 100%; height: 240px"
    >
      <img
        v-for="image in listFiles"
        :key="image.name"
        :src="image.url"
        :alt="image.name"
        class="carousel-img"
      />
    </n-carousel>
  </div>
</template>

<style lang="scss" scoped>
.carousel-img {
  width: 100%;
  height: 240px;
  object-fit: contain;
}

:deep(.n-carousel .n-carousel__arrow) {
  color: #2c3e50;
}
</style>

<script>
import { NCarousel } from "naive-ui";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Carousel",
  props: {
    listFiles: { type: Object, required: true },
    show_arrow: { type: Boolean, required: false, default: false },
    autoplay: { type: Boolean, required: false, default: false },
  },
  components: {
    NCarousel,
  },
});
</script>
